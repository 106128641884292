<template>
	<el-dialog :visible.sync="show" width="40%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div v-if="!userShowList">
			<div slot="title" class="header-title">
				<el-row :gutter="5">
					<el-col :span="24">
						<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>
					</el-col>
				</el-row>
			</div>
			<div style="width: 100%;height: 20px;"></div>
			<el-row :gutter="0">
				<el-col :span="24">
					<el-form ref="ruleForm" :model="form" label-width="110px" class="allForm">
						<el-form-item label="报警类型" :required="true">
							<el-select v-model="form.typeId" class="input searchInput" filterable clearable @change="alarmTypeChange">
								<el-option v-for="(v, i) in alarmType" :key="i" :value="v.id" :label="v.alarmEvent" />
							</el-select>
						</el-form-item>
						<el-form-item label="报警事件" :required="true">
							<el-select v-model="form.eventId" class="input searchInput" filterable clearable>
								<el-option v-for="(v, i) in alarmEvent" :key="i" :value="v.id" :label="v.alarmEvent" />
							</el-select>
						</el-form-item>
						<el-form-item label="周期" :required="true">
							<el-input v-model="form.cycle" @input="inputClick1" class="formInput" type="number"/>
						</el-form-item>
						<el-form-item label="编码（2011）">
							<el-input v-model="form.code2011" class="formInput" maxlength="25" />
						</el-form-item>
						<el-form-item label="编码（2019）">
							<el-input v-model="form.code2019" class="formInput" maxlength="25" />
						</el-form-item>
						<el-form-item label="纠偏视频" :required="true">
							<div class="boxInput">
								<el-select v-model="form.videoIds" class="input searchInput" filterable clearable multiple>
									<el-option v-for="(v, i) in videoInfo" :key="i" :value="v.id" :label="v.videoTitle" />
								</el-select>
							</div>
						</el-form-item>
						<el-form-item label="随机视频数" :required="true">
							<el-input v-model="form.randomVideoNum" @input="inputClick" class="formInput" type="number" maxlength="25" />
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer" style="text-align: center;">
				<el-button type="primary" @click="commit">提交</el-button>
				<el-button type="info" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: this.dialog,
				form: {
					code2011: '',
					code2019: '',
					cycle: '',
					eventId: '',
					randomVideoNum: '',
					typeId: '',
					videoIds: []
				},
				alarmType: [],
				alarmEvent: [],
				videoInfo: [],
			}
		},
		created() {},
		mounted() {
			this.getAlarmType();
			this.getVideoInfo();
			this.upd()
		},
		methods: {
			getAlarmType() {
				this.loading = true
				this.$axiosReq('/adapter/server/escort/web/alarmConfig/alarmType', null, null, 'get').then(res => {
					this.alarmType = res.data;
				}).finally(e => {
					this.loading = false
				})
			},
			alarmTypeChange() {
				this.loading = true
				this.$axiosReq('/adapter/server/escort/web/alarmConfig/alarmEvent/' + this.form.typeId, null, null, 'get').then(res => {
					this.alarmEvent = res.data;
				}).finally(e => {
					this.loading = false
				})
			},
			getVideoInfo() {
				this.loading = true
				this.$axiosReq('/adapter/server/escort/web/alarmConfig/videoInfo', null, null, 'get').then(res => {
					this.videoInfo = res.data;
				}).finally(e => {
					this.loading = false
				})
			},
			upd() {
				if (this.info.addOr === 'upd') {
					this.loading = true
					this.$axiosReq('/adapter/server/escort/web/alarmConfig/' + this.row.id, null, null, 'get').then(res => {
						this.form.code2011 = res.data.code2011;
						this.form.code2019 = res.data.code2019;
						this.form.cycle = res.data.cycle;
						this.form.eventId = res.data.id;
						this.form.randomVideoNum = res.data.randomVideoNum;
						this.form.typeId = res.data.parentId;
						this.form.videoIds = res.data.videoIds;
						this.alarmTypeChange();
					}).finally(e => {
						this.loading = false
					})
				}
			},
			// 提交添加
			commit() {
				if (!this.form.typeId) {
					this.$message.error('请选择报警类型');
					return false;
				}
				if (!this.form.eventId) {
					this.$message.error('请选择报警事件');
					return false;
				}
				if (this.form.videoIds.length < 1) {
					this.$message.error('请选择视频');
					return false;
				}
				if (this.form.randomVideoNum < 0) {
					this.$message.error('随机视频数不能小于0');
					return false;
				}
				if (this.form.cycle < 0) {
					this.$message.error('周期不能小于0');
					return false;
				}
				if (this.info.addOr === 'add') {
					this.$axiosReq('/adapter/server/escort/web/alarmConfig', this.form, null, 'post').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				} else {
					this.$axiosReq('/adapter/server/escort/web/alarmConfig/' + this.row.id, this.form, null, 'put').then(res => {
						this.close()
						this.$emit('get-list')
						this.$message.success(res.msg)
					})
				}
			},
			// 关闭模态框
			close() {
				this.show = false
				this.$emit('refresh')
			},
			inputClick1(e) {
				this.form.cycle = e.split('.')[0];
			},
			inputClick(e) {
				this.form.randomVideoNum = e.split('.')[0];
			},
		}
	}
</script>

<style scoped>
	.input-with-select {
		width: 280px;
	}

	.pb-20 {
		padding-bottom: 20px;
	}
	
</style>
