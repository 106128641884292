<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="refreshDialog" />
		<el-card style="margin-top: 20px;">
			<el-row>
			<!-- 	<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="space-between">
							<el-col>
								<el-button type="primary" size="mini" @click="openDialog({title:'添加',addOr:'add'})">添加</el-button>
								<el-button type="danger" size="mini" @click="removeAll({del:'batch'})">批量删除</el-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar> -->
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="760" row-id="company_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column type="seq" width="100" />
					<vxe-table-column field="alarmType" title="报警类型" show-overflow="title" />
					<vxe-table-column field="alarmEvent" title="报警事件" show-overflow="title" />
					<vxe-table-column field="code2011" title="编码（2021）" show-overflow="title" />
					<vxe-table-column field="code2019" title="编码（2019）" show-overflow="title" />
					<vxe-table-column field="cycle" title="周期（天）" show-overflow="title" />
					<vxe-table-column field="videoNames" title="纠偏学习视频" show-overflow="title" />
					<!-- <vxe-table-column title="纠偏学习视频" show-overflow="title">
					    <template v-slot="{ row }">
					        <span v-for="(item,index) in row.videoNames" :key="index">{{item}}</span>
					    </template>
					</vxe-table-column> -->
					<vxe-table-column field="randomVideoNum" title="随机视频数" show-overflow="title" />
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'配置',addOr:'upd'},row)">修改</el-dropdown-item>
									<!-- <el-dropdown-item icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item> -->
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import Operate from './Operate'
	export default {
		name: 'Index',
		components: {
			Operate
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false,
				},
				searchText: '',
			}
		},
		created() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
			},
			getList() {
				this.loading = true
				this.$axiosReq('/adapter/server/escort/web/alarmConfig/pageAlarmConfig', null, {
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(res.data.totalElements)
				}).finally(e => {
					this.loading = false
				})
			},
			removeAll() {
				const id = []
				const arr = this.$refs.table.getCheckboxRecords()
				for (const i of arr) {
					id.push(i.id)
				}
				if (!id || id.length < 1) {
					this.$message.info('请选择需要删除的对象！')
					return false
				}
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/adapter/server/escort/web/alarmConfig', id, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			remove(info, row) {
				this.$confirm('确认删除吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axiosReq('/adapter/server/escort/web/alarmConfig/' + row.id, null, null, 'delete').then(res => {
						this.$message.success(res.msg)
						this.getList()
					})
				}).catch(() => {
					this.$message.info('取消了删除！！！')
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			refreshDialog() {
				this.dialog.refresh = false
				this.getList()
			},
		}
	}
</script>

<style scoped>

</style>
